@import url("https://fonts.googleapis.com/css?family=Raleway:200,700|Source+Sans+Pro:300,600,300italic,600italic");
@import "font-awesome";
@import "bootstrap";
@import "site-variables";
@import "bootstrap-select";
@import "bootstrap-chosen";

@igreen:             #dff0d8;

body {
  background: darken(@navbar-default-bg, 20%);
}

a, button, input[type=submit] {
  transition: all 0.3s;
}

.green { color: #3c6; }

.media-item img {
  border-radius: @border-radius-base;
}

.testimonial {
  margin-top: 20px;
  position: relative;

  .name {
    font-weight: bold;
    padding-left: 85px;
    color: darken(@navbar-default-bg, 20%);
  }

  .text {
    padding-left: 85px;
  }

  .face {
    width: 70px;
    height: 70px;
    background-position: center center;
    background-size: cover;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 50%;
  }
}

.content {
  background: #fff;
  padding: floor(@navbar-height/2) 0;
}

.header {
  background: #fff;
  padding: floor(@navbar-height/2) 0;

  .container {
    position: relative;
  }

  .partner-logo {
    margin-left: 25px;

    img {
      border-radius: @border-radius-base;
    }
  }

  .migrated {
    background: #fafafa;
    opacity: 0.7;
    display: inline-block;
    text-align: center;
    line-height: 40px;
    position: absolute;
    top: floor(@navbar-height/2);
    right: 15px;
    border-radius: @border-radius-base;
    padding: 0 30px;

    a {
      color: #000;
    }
  }
}

.footer {
  padding: floor(@navbar-height/2) 0;
  color: @navbar-default-color;

  a {
    color: @navbar-default-color;
  }

  h4 .fa {
    padding-right: 5px;
    padding-bottom: floor(@navbar-height/2.5);
  }

  .copy,
  .copy a {
    color: lighten(@navbar-default-bg, 20%);
  }
}

.footer table tr:first-child td {
  border: 0;
}

.pre-footer {
  background: #eee;
  padding: 22px 0 30px;

  a:hover {
    text-decoration: none;
    color: darken(@navbar-default-bg, 40%);
  }
}

.reservation-tabs .nav-tabs {
  border-bottom: 0;
  margin-bottom: 5px;
  margin-top: 0;
  > li {
    float: left;
    // Make the list-items overlay the bottom border
    margin-bottom: -1px;

    // Actual tabs (as links)
    > a {
      margin-right: 2px;
      line-height: @line-height-base;
      border-radius: @border-radius-base;
      &:hover {
        color: @nav-pills-active-link-hover-color;
        background-color: @nav-pills-active-link-hover-bg;
      }
    }

    // Active state, and its :hover to override normal :hover
    &.active > a {
      &,
      &:hover,
      &:focus {
        color: @nav-pills-active-link-hover-color;
        background-color: @nav-pills-active-link-hover-bg;
      }
    }
  }
}

.reservation-tabs .tab-pane {
  border-radius: @border-radius-base;
  border: 1px solid @nav-pills-active-link-hover-bg;
}



.navbar-toggle .button-label {
  display: inline-block;
  float: left;
  font-weight: bold;
  line-height: 14px;
  padding-right: 10px;
  color: @navbar-default-color;
  text-transform: uppercase;
}

.button-bars {
  display: inline-block;
  float: left;
}

.big-loader {
  text-align: center;
  padding: 50px;
  font-size: 500%;
}

.ib-calendar {
  .today {
    background: #ffc;
  }

  .past {
    opacity: 0.3;
  }

  .past .btn-slots {
    display: none;
  }

  .calendar-day {
    border: 2px solid @table-border-color;
    padding: @table-condensed-cell-padding;
    margin-bottom: @table-condensed-cell-padding * 2;
    border-radius: @border-radius-base;

    h5 {
      font-size: 150%;
      line-height: 35px;
      font-weight: bold;
      margin: (@table-condensed-cell-padding * 2) 0;
      padding-left: (@table-condensed-cell-padding * 2);
    }

    .progress {
      margin-bottom: 5px;
      height: 5px;
    }

    .slot {
        text-decoration: none;
        display: block;
        background: darken(#eee, 10%);
        padding: @table-condensed-cell-padding;
        margin-top: @table-condensed-cell-padding;
        border-radius: @border-radius-base;
        color: #666;

    }
    .slot:hover {
        background: @igreen;

        .progress {
          background: #fff;
        }
    }

    .test {
      p {
        margin: 0;
      }
    }

    .reserved {
        background: darken(spin(@igreen, -10), 10%);

        .progress {
          background: #fff;
        }
    }

    .full, .full:hover {
      background: darken(@state-danger-bg, 10%);
    }
  }
}

.btn-default, .btn-block {
  text-transform: uppercase;
}

.page-title h3 {
  margin: 10px 0;
}

.page-title .btn {
  margin: 30px 0 0 0;
}

.tab-pane {
  border-left: 1px solid @table-border-color;
  border-right: 1px solid @table-border-color;
  border-bottom: 1px solid @table-border-color;
  border-radius: 0 0 5px 5px;
  padding: 20px;
}

.nav-tabs {
  margin-bottom: 0;
}

.dropzone {
  min-height: 130px;
  border: 1px dashed @table-border-color;
  padding: 20px;
}

.dropzone .dz-preview .dz-image {
  border-radius: @border-radius-base;
}

.datepicker-container {
  border-radius: @border-radius-base;
  border: 1px solid @input-border;
  .box-shadow(inset 0 1px 1px rgba(0,0,0,.075));
}

.table-delete-item {
  color: indianred;
}

.media-item {
  width: 150px;
  height: 150px;
  background-size: cover;
  margin: auto;
  .img-rounded;
}

.file-item {
  height: 150px;
  margin: auto;
}

.media-item-container {
  text-align: center;
}

.media-top-row {
  text-align: right;
}

.thumbnail.danger{
  background: @state-danger-bg;
}

.thumbnail form {
  height: 40px;
}

.thumbnail .alert{
  padding: 7px 0 6px 0;
  text-align: center;
  margin-bottom: 0;
}

.ib-calendar .col-sm-1 {
  @media (min-width: @screen-sm-min) {
    width: 14.2857%;
  }
}

.chosen-container { /* fix for bootstrap modal */
  width: 100% !important;
}

